<template>
  <section class="project-house-budget">
    <section class="hero-small">
      <div>
        <h1>
          Quel budget pour faire construire sa maison ?
        </h1>
      </div>
    </section>
    <div class="grid-container">

      <card-type-img
        title="Quel est le prix au m2 d’une maison neuve en 2022 ?">
        <template v-slot:content>
          <p>
            <strong>Prix moyen chez un constructeur en France : 1 800 €/m2</strong> <br>
            <strong>Prix moyen chez Maisons Alysia : 1 375 €/m2</strong>
          </p>

          <p>
            Ce <router-link :to="{ name: 'price-square-meter' }">prix au m2 dépend de nombreuses variables</router-link> : le constructeur et son mode constructif, le niveau des prestations, des finitions et équipements, la nature du terrain, la situation géographique…
            Mais incontestablement, Maisons Alysia vous offre le meilleur de la maison au meilleur prix.
          </p>

          <p>Découvrez quel est le <router-link :to="{ name: 'smart-house-plan' }">prix du m2</router-link> supplémentaires sur nos plans de maison intelligents :</p>
          <ul>
            <li>agrandir en ligne un de nos modèles de maison : un jeu d’enfant… </li>
            <li>constater immédiatement l’impact sur le prix global : un vrai régal !</li>
          </ul>
        </template>
        <template v-slot:img>
          <img src="@/assets/img/pages/house-price.jpg" alt="Un joli intérieur de maison" />
        </template>
      </card-type-img>

      <card-type-img
        title="Une maison pas chère ? Les yeux fermés ?">
        <template v-slot:content>
          <p>
            <router-link :to="{ name: 'low-cost-house' }">Une maison pas chère</router-link> est une maison qui, sans sacrifier à la qualité, offre le meilleur tarif du marché local. <br>
            Les constructeurs entrée de gamme dégradent le niveau des matériaux et celui des équipements sans exiger l’excellence sur les chantiers. <br>
            Ils omettent également certains postes considérés comme secondaires pour faire baisser la note mais que l’acquéreur devra de toutes façons prendre en charge. <br>
            Chez Maisons Alysia, pas de ça ! La qualité globale est une exigence couplée aux vertus de la transparence : nos tarifs de construction en ligne incluent tous les frais annexes et même ceux liés au terrain (frais de notaires, frais de branchements, etc… ).
          </p>
        </template>
        <template v-slot:img>
          <img src="@/assets/img/pages/closed-eye-woman.jpg" alt="Femme qui se cache les yeux" />
        </template>
      </card-type-img>

      <card-type-img
        title="Des prix bas ... mais pas une maison low cost !">
        <template v-slot:content>
          <p>
            Bien que proposant les prix les plus bas constatés sur le marché, Maisons Alysia ne peut être considérée comme une marque low cost. Pourquoi ?
          </p>
          <p>
            Parce que, bénéficiant de la puissance d’achat de Group Alysia, l’offre Maisons Alysia rassemble les matériaux, les finitions, les équipements et le mode constructif d’un des constructeurs les plus exigeants sur le marché.<br/>
            De même, il serait inconcevable pour Maisons Alysia de mettre en œuvre la construction d’une maison sans offrir tous les services, <router-link :to="{ name: 'securities' }">les garanties et les assurances dignes d’un grand constructeur</router-link>.
          </p>
        </template>
        <template v-slot:img>
          <img src="@/assets/img/pages/wood-house-garden.jpg" alt="Une maison contemporaine avec bardage bois" />
        </template>
      </card-type-img>

    </div>
  </section>
</template>

<script>
import CardTypeImg from '@/components/general/CardTypeImg.vue';

export default {
  name: 'project-house-budget',
  components: {
    CardTypeImg,
  },
};
</script>

<style lang="sass">
  .project-house-budget
    padding: 0 0 4rem
    background: $medium-bg
    .hero-small
      @include hero-small
      background-image: linear-gradient(180deg, rgba(10, 10, 10, 0.6) 0%, rgba(32, 32, 32, 0.2) 100%), url(~@/assets/img/pages/house-budget.jpg)

    .intro
      margin: 6rem auto 5rem
      padding: 0 1rem
      max-width: 925px
      text-align: center

    .card-type-img
      margin: 3rem auto
      .img
        min-width: initial
      p ~ p
        margin-top: 1.5rem
      .content p a
        font-weight: 400

    .card-find-house
      @include card-find-house
      margin: 3rem auto
      &.left
        h2, h3
          text-align: left

    .card-content
      @include card-content
      display: flex
      align-items: center
      text-align: center
      margin: 1rem 0 5rem
      padding: 5rem 1rem
      background: $primary
      h2
        margin-bottom: 1rem
        max-width: 770px
        color: $white
      p
        max-width: 925px
        &.highlight
          color: $white
          font-size: 18px
          line-height: 29px
      @media (max-width: 768px)
        padding: 3rem 2.5rem 1rem

    .cards
      margin: 3rem 0 2rem
      @media (max-width: 768px)
        margin-top: 1rem

    .card-icon
      @include card-icon
      margin: 0 1rem
</style>
